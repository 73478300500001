<template>
  <v-container fluid>
    <v-toolbar
        color="white"
        flat
    >

      <v-toolbar-title class="grey--text text--darken-4">
        Overzicht accomodaties
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn to="accomodation/new" color="primary">
        Toevoegen
      </v-btn>
    </v-toolbar>
    <v-list >
      <v-list-item link :to="`accomodation/`+a.ID" v-for="a in accomodations" :key="a.ID">
        <v-list-item-content >
          <v-list-item-title>{{ a.Name }}</v-list-item-title>
          <v-list-item-action-text>Aantal: {{ a.Qty }} | Gasten: {{ a.MaxGuests }}</v-list-item-action-text>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>

import {Accomodation} from "@/models/accomodation";

const {AccomodationService} = require("@/services/accomodation-service");
export default {
  data(){
    return{
      accomodations: Array[Accomodation] = []
    }
  },
  async created() {
    this.accomodations = await AccomodationService.get();
  }
}
</script>

<style scoped>
  .v-list-item:first-child{ border-top: 1px solid rgba(0, 0, 0, 0.12); }
  .v-list-item{ border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
</style>